<template lang="pug">
layoutContainer
  .enter-container
    .content(:style="{'min-height': `${containerHeight}px`}")
      .content-bar
        .title 营销中心
        .sub-bar(
          :class="`${selectType === item.value ? 'sub-bar-select' : ''}`"
          v-for="(item, index) in tabBarList"
          v-show="([7,8,9,10].includes(item.value) && isOperator) || (item.value && ![7,8,9,10].includes(item.value))"
          :key="index"
          @click="selectType = item.value"
        ) 
          span(v-if="!item.icon") {{ item.name }}
          img(v-if="item.icon" :src="item.icon", alt="", srcset="")
          span(v-if="item.icon" style="font-size: 16px;") {{ item.name }}
      .bar-content
        saleByProxy(v-if="selectType === 1")
        share(v-if="selectType === 2")
        guidance(v-if="selectType === 3")
        promotion(v-if="selectType === 4")
        recommend(v-if="selectType === 5")
        relevancy(v-if="selectType === 6")
        outboundOrder(v-if="selectType === 7")
        coupon(v-if="selectType === 8")
        activity(v-if="selectType === 9")
        message(v-if="selectType === 10")
</template>
<script>
import saleByProxy from '../../components/orderAccount/saleByProxy.vue'
import share from '../../components/orderAccount/share.vue'
import guidance from '../../components/orderAccount/guidance.vue' // 导流
import promotion from '../../components/orderAccount/promotion.vue' // 推广
import outboundOrder from '../../components/orderAccount/outboundOrder.vue' // 推广
import message from '../../components/orderAccount/message.vue' // 推广
import coupon from '../../components/orderAccount/coupon.vue' // 推广
import activity from '../../components/orderAccount/activity.vue' // 推广
import relevancy from '../../components/orderAccount/relevancy.vue' // 关联
import recommend from '../../components/orderAccount/recommend.vue' // 引荐
import {checkAuth} from '@/api/product'

export default {
  components: {
    saleByProxy,
    share,
    guidance,
    promotion,
    message,
    outboundOrder,
    activity,
    coupon,
    relevancy,
    recommend
  },
  data() {
    return {
      containerHeight: 0,
      selectType: 1,
      isOperator: false,
      tabBarList: [
        {
          name: '代销',
          value: 1
        },
        {
          name: '分享',
          value: 2
        },
        {
          name: '导流',
          value: 3
        },
        {
          name: '推广',
          value: 4
        },
        {
          name: '引荐',
          value: 5
        },
        {
          name: '关联',
          value: 6
        },
        {
          name: '站外单',
          value: 7
        },
        {
          name: '代金券',
          value: 8
        },
        {
          name: '活动',
          value: 9
        },
        {
          name: '短信',
          value: 10
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      const screenHeight = document.documentElement.clientHeight
      const headerHeight = document.getElementsByClassName('headerBox')[0].offsetHeight
      const footerHeight = document.getElementsByClassName('footer-container')[0].offsetHeight
      this.containerHeight = screenHeight - headerHeight - footerHeight - 70 - 36 - 55
    })
    checkAuth({and: true, authList: [1]}).then(res => {
      this.isOperator = res.code === 200
    })
    this.selectType = Number(this.$route.query.type || 1)
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.enter-container {
  width: 1200px;
  overflow: scroll;
}
.title {
  text-align: center;
}
.content {
  height: 100%;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  background-color: #ffffff;
}
.sub-bar {
  width: 148px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  width: 100%;
  cursor: pointer;
  img {
    width: 32px;
    margin-right: 6px;
    // background-color: #fff;
  }
}
.sub-bar-select {
  color: #5ad3cd;
  background-image: url('./../../assets/tabBg.png');
  background-size: 100% 100%;
}
.content-bar {
  width: 169px;
  background: #5ad3cd;
  min-height: 100%;
  box-sizing: border-box;
  padding-top: 35px;
  box-sizing: border-box;
  padding-left: 31px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  line-height: 86px;
  text-align: left;
}
.bar-content {
  flex: 1;
  background-color: #fff;
  min-height: 100%;
}
.notice {
  padding: 24px 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 20px 88px;
  display: block;
  justify-content: flex-start;
  flex-direction: column;
  span {
    display: block;
    margin-bottom: 24px;
  }
}
.notice-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
}
</style>
