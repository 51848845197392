<template lang="pug">
.content
  layoutContainer
    .content(:style="{'min-height': `${containerHeight}px`, background: '#ffffff', padding: '32px 40px', 'border-radius': '12px'}")
      .search-tab
        .search-name
          .back(@click="$router.go(-1)")
            img(src="/img/back-icon-video.png")
            span 返回
          .class-name
            .label 产品名称
            | {{ courseName }}
        .search-nav
          input.search-input(placeholder="发起人账号" v-model="initiateUserName")
          img.search-icon(src="/img/search-icon.png" @click="search")
      .nav-tab
        .nav
          .tab(:class="`${bargainTogetherType === -1 ? 'selected' : ''}`" @click="bargainTogetherType = -1")
            span 全部
            .select-icon(v-if="bargainTogetherType === -1")
          .tab(:class="`${bargainTogetherType === 1 ? 'selected' : ''}`" @click="bargainTogetherType = 1")
            span 砍价
            .select-icon(v-if="bargainTogetherType === 1")
          .tab(:class="`${bargainTogetherType === 2 ? 'selected' : ''}`" @click="bargainTogetherType = 2")
            span 拼团
            .select-icon(v-if="bargainTogetherType === 2")
        .btns(v-if="!isAuthor && !hasBuyTag")
          .bargainirg(@click="kanjia")
            img(src="/img/kanjia-icon.png", alt="", srcset="")
            span 发起砍价
          .bargainirg(@click="setPintuan")
            img(src="/img/persion-icon.png", alt="", srcset="")
            span 发起拼团
      div(style="margin-left: 40px;")
        el-table.table(:data="list" stripe)
          el-table-column(prop="initiateUserName" label="发起人账号")
          el-table-column(prop="describe" label="请求")
          el-table-column(prop="describe" label="进度")
            template(slot-scope="scope")
              el-tag(@click="getUserList(scope.row.type, scope.row.requestId)" style="cursor: pointer;") {{ scope.row.describe }}
          el-table-column(prop="residueTime" label="剩余时间")
          el-table-column(prop="discounts" width="200")
            template(slot-scope="scope")
              div.action(v-if="scope.row.status === 0 && scope.row.type === 1" @click="bargainJoin(scope.row.requestId)") 帮砍
              div.action(v-if="scope.row.status === 0 && scope.row.type === 2 && !isAuthor" @click="togetherJoin(scope.row.requestId)") 一起拼
              div.action.disabled(v-if="scope.row.status === 1 && scope.row. type === 1") 已帮砍
              div.action.disabled(v-if="scope.row.status === 1 && scope.row. type === 2  && !isAuthor") 已拼团
      el-pagination.pagination(background layout="prev, pager, next" :total="total" @current-change="currentChange")
      .notice
        .notice-container
          .notice-title 
            img(src="/img/kanjiabaise.png", alt="", srcset="")
            | 砍价规则
          .notice-bargain-describe 
            span(v-html="`${bargainDescribe.substring(0, 23)}${ bargainDescribe.length <= 23 ? '' : '...'}`" v-if="!openDargainShow") 
            span.open(@click="openDargainShow = true" v-if="!openDargainShow && togetherDescribe.length > 23")  展开
            p(v-html="`${bargainDescribe}`" v-if="openDargainShow")  
          .notice-bargain-stat-describe(v-html="bargainStatDescribe") 
        .notice-container(style="border: 0")
          .notice-title 
            img(src="/img/more-persion.png", alt="", srcset="")
            | 拼团规则
          .notice-bargain-describe 
            span(v-html="`${togetherDescribe.substring(0, 4)}${ togetherDescribe.length <= 23 ? '' : '...'}`" v-if="!openTogetherShow") 
            span.open(@click="openTogetherShow = true" v-if="!openTogetherShow && togetherDescribe.length > 23")  展开
            p(v-html="`${togetherDescribe}`" v-if="openTogetherShow")  
          .notice-bargain-stat-describe(v-html="togetherStatDescribe") 
    dialogMask(:show.sync="showJoinDialog", :showClose="true")
      .title
        img(src="/img/kanjiabaise.png" v-if="joinType === 1")
        img(src="/img/more-persion.png" v-if="joinType === 2")
        p {{ joinType === 1 ? '帮砍记录' : '拼团用户' }}
      .content(v-if="joinType === 1")
        .join-list(v-for="item in joinList")
          .join-name {{ item.joinBargainUserName }}
          .join-desc 砍掉 {{ item.bargainPercent * 100 }}%
          .join-is-new(v-if="item.isNewUser === 1") 
            el-tag(type="success" style="margin-right:5px;") 新用户
      .content(v-if="joinType === 2") 
        .join-list(v-for="item in joinList")
          .join-name {{ item.togetherUserName }}
          .join-desc 已拼
    dialogMask(:show.sync="showTogetherDialog", :showClose="true")
      .title
        img(src="/img/more-persion.png")
        p 发起拼团
      .content
        div.form-pintuan
          .label 拼团人数
          el-select(v-model="pintuanSelect" placeholder="请选择" value-key="id")
            el-option(
              v-for="item in pintuanList"
              :key="item.id"
              :label="item.name"
              :value="item"
            )
        div.form-pintuan
          .label 拼团价
          span {{ pintuanSelect.togetherPrice }}
        div.form-pintuan
          .label 折扣 
          span {{ pintuanSelect.discount * 10 }} 折
        div(style="width: 195px;margin: 0 auto;")
          .bargainirg(@click="getTogether")
            img(src="/img/persion-icon.png", alt="", srcset="")
            span 发起拼团

</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {pay} from '@/api/subject'
import {
  bargainAndTogether,
  togetherJoinList,
  bargainJoinList,
  bargainSave,
  togetherOptionList,
  togetherSave,
  bargainJoin,
  togetherJoin
} from '@/api/project'
import {checkAuth} from '@/api/product'
export default {
  name: 'bargainirgPage',
  components: {dialogMask},
  data() {
    return {
      courseId: '',
      courseName: '',
      openTogetherShow: false,
      openDargainShow: false,
      showJoinDialog: false,
      joinType: '',
      pintuanSelect: {},
      joinList: [],
      hasBuyTag: false,
      showTogetherDialog: false,
      isOperator: false,
      containerHeight: 0,
      bargainTogetherType: -1, // -1-全部；1-砍价；2-拼团
      initiateUserName: '',
      total: 0,
      bargainDescribe: '',
      isAuthor: false,
      bargainStatDescribe: '',
      togetherDescribe: '',
      pintuanList: [],
      togetherStatDescribe: '',
      list: []
    }
  },
  watch: {
    bargainTogetherType() {
      this.currentChange(1)
    }
  },
  created() {
    checkAuth({and: true, authList: [1]}).then(res => {
      this.isOperator = res.code === 200
    })
    const {courseId, courseName, bargainTogetherType} = this.$route.query
    if (!courseId) this.$router.push('/home')
    this.bargainTogetherType = Number(bargainTogetherType)
    this.courseId = courseId
    this.courseName = courseName
    this.$nextTick(() => {
      const screenHeight = document.documentElement.clientHeight
      const headerHeight = document.getElementsByClassName('headerBox')[0].offsetHeight
      const footerHeight = document.getElementsByClassName('footer-container')[0].offsetHeight
      this.containerHeight = screenHeight - headerHeight - footerHeight - 70
    })
    this.search()
  },
  beforeDestroy() {
    this.list.forEach(item => {
      clearInterval(item.timer)
    })
  },
  methods: {
    search() {
      const params = {
        productId: this.courseId,
        businessType: '知识视频',
        bargainTogetherType: this.bargainTogetherType,
        initiateUserName: this.initiateUserName,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      bargainAndTogether(params).then(res => {
        if (this.list.length !== 0) {
          this.list.forEach(item => {
            clearInterval(item.timer)
            item.timer = null
          })
        } else {
          this.list = res.data.records
          this.list.forEach((item, index) => {
            let requestTime = new Date(item.requestTime).getTime()
            let remainingTime = new Date(item.remainingTime).getTime()
            let now = new Date().getTime()
            let orderTime = 0
            let residueTime = ''
            if (now < remainingTime) {
              orderTime = remainingTime - now
              residueTime = this.formatRemainingTime(orderTime)
            } else {
              orderTime = '-'
              residueTime = '-'
            }
            console.log('%c [ orderTime ]-207-「bargainirgPage」', 'font-size:px;', orderTime)
            this.$set(this.list[index], 'orderTime', orderTime)
            this.$set(this.list[index], 'residueTime', residueTime)
            item.timer = setInterval(() => {
              
              if (item.orderTime === '-' || item.orderTime === 0 || item.orderTime < 0) {
                this.$set(this.list[index], 'orderTime', '-')
                this.$set(this.list[index], 'residueTime', '-')
                clearInterval(item.timer)
              } else {
                let orderTime = item.orderTime - 1000
                let residueTime = this.formatRemainingTime(orderTime)
                this.$set(this.list[index], 'orderTime', orderTime)
                this.$set(this.list[index], 'residueTime', residueTime)
              }
              
            }, 1000)
          })
        }
        this.total = res.data.total
        this.bargainDescribe = res.data.bargainDescribe
        this.bargainStatDescribe = res.data.bargainStatDescribe
        this.togetherDescribe = res.data.togetherDescribe
        this.togetherStatDescribe = res.data.togetherStatDescribe
        this.isAuthor = res.data.isAuthor
        this.hasBuyTag = res.data.hasBuyTag
      })
    },
    formatRemainingTime(remainingSeconds) {
      let time = remainingSeconds / 1000
      // 计算小时数
      const hours = Math.floor(time / 3600);
      // 剩余秒数（去掉小时对应的秒数后）
      const remainingSecondsAfterHours = time % 3600;
      // 计算分钟数
      const minutes = Math.floor(remainingSecondsAfterHours / 60);
      // 计算最终的秒数
      const seconds = remainingSecondsAfterHours % 60;

      // 使用padStart方法对数字进行格式化，如果小于10则在前面补0
      const formattedHours = hours.toFixed(0).toString().padStart(2, '0');
      const formattedMinutes = minutes.toFixed(0).toString().padStart(2, '0');
      const formattedSeconds = seconds.toFixed(0).toString().padStart(2, '0');
      return `${formattedHours}-${formattedMinutes}-${formattedSeconds}`;
      
    },
    bargainJoin(requestId) {
      const params = {
        requestId: requestId
      }
      bargainJoin(params).then(res => {
        if (res.requestId) {
          this.$message.success(`帮砍成功`)
          this.pageNum = 1
          this.search()
        } else {
          this.$message.error(res.data)
        }
      })
    },
    togetherJoin(requestId) {
      const params = {
        requestId: requestId
      }
      togetherJoin(params).then(res => {
        if (res.code === 200) {
          this.$message.success(`拼团成功`)
          this.pageNum = 1
          this.search()
        } else {
          this.$message.error(res.data)
        }
      })
    },
    kanjia() {
      const params = {
        productId: this.courseId,
        businessType: '知识视频'
      }
      bargainSave(params).then(res => {
        if (res.code === 100003) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success(`发起砍价成功`)
        this.pageNum = 1
        this.search()
      })
    },
    getTogether() {
      const params = {
        productId: this.courseId,
        businessType: '知识视频',
        togetherDetailId: this.pintuanSelect.id
      }
      togetherSave(params).then(res => {
        if (res.code === 200) {
          this.$message.success(`拼团成功`)
          this.showTogetherDialog = false
          this.pageNum = 1
          this.search()
          this.$router.push({
            path: '/pay',
            query: {
              courseId: this.courseId,
              togetherDetailId: this.pintuanSelect.id,
              orderId: res.data
            }
          })
          // pay({id: this.courseId}).then(res => {
          //   if (res.code === 200009) {
          //     this.$router.push({
          //       path: '/login'
          //     })
          //   }
          //   const {code, data} = res
          // if (code === 200 && data) {

          //   } else {
          //     this.$dialog.toast(res.msg)
          //   }
          // })
          // return
        } else {
          this.$message.error(res.data)
        }
      })
    },
    setPintuan() {
      this.showTogetherDialog = true
      const params = {
        productId: this.courseId,
        businessType: '知识视频'
      }
      togetherOptionList(params).then(res => {
        this.pintuanList = res.data
        this.pintuanSelect = res.data[0]
      })
    },
    searchUser() {
      this.pageNum = 1
      this.bargainTogetherType = -1
      this.search()
    },
    getUserList(type, requestId) {
      const request = type == 1 ? bargainJoinList : togetherJoinList
      request({
        requestId
      }).then(res => {
        this.showJoinDialog = true
        this.joinList = res.data
        this.joinType = type
      })
    },
    currentChange(page) {
      this.pageNum = page
      this.search()
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 30px 0;
}
.search-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 66px;
}
.search-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  span {
    font-weight: 400;
    font-size: 24px;
    color: #acacac;
    line-height: 28px;
    margin-right: 32px;
  }
  .class-name {
    padding: 0 20px 0 0;
    border-radius: 24px 24px 24px 24px;
    background: #efefef;
    height: 47px;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .label {
      background: #5ad3cd;
      border-radius: 24px 24px 24px 24px;
      width: 120px;
      height: 47px;
      margin-right: 8px;
      font-weight: 600;
      font-size: 22px;
      color: #ffffff;
      line-height: 26px;
      text-align: center;
      line-height: 47px;
    }
  }
}
.back {
  cursor: pointer;
}
.search-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.search-input {
  width: 300px;
  height: 47px;
  border-radius: 24px 24px 24px 24px;
  border: 1px solid #acacac;
  margin-right: 16px;
  padding: 0 0 0 22px;
}
.search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.nav-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
  margin-bottom: 67px;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tab {
  margin-right: 56px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
  }
  .select-icon {
    width: 16px;
    height: 4px;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
  }
}
.selected {
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    line-height: 26px;
  }
}
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.bargainirg {
  height: 56px;
  background: #ff724c;
  border-radius: 34px 34px 34px 34px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px !important;
    color: #ffffff !important;
    text-align: center;
  }
}
.action {
  text-align: center;
  width: 130px;
  height: 47px;
  background: #5ad3cd;
  border-radius: 28px 28px 28px 28px;
  line-height: 47px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
}
.disabled {
  background: #efefef;
  border-radius: 28px 28px 28px 28px;
  color: #acacac;
}
.notice {
  padding: 24px 12px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  .notice-container {
    border-right: 2px solid #e7e7e7;
    box-sizing: border-box;
    width: 50%;
    padding: 0 24px;
  }
  .notice-right {
    box-sizing: border-box;
    width: 50%;
  }
  img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
  .notice-title {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 22px;
    color: #6d6d6d;
    line-height: 26px;
    align-items: center;
  }
  .notice-bargain-describe {
    margin-bottom: 24px;
    p,
    span {
      font-weight: 600;
      font-size: 16px;
      color: #6d6d6d;
      line-height: 19px;
      white-space: normal; /* 允许自动换行 */
      word-break: break-all;
    }
    .open {
      font-weight: 600;
      font-size: 16px;
      color: #5ad3cd;
      line-height: 19px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  .notice-bargain-stat-describe {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    color: #6d6d6d;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .label-text {
    color: #ff724c;
    font-size: 24px;
  }
}
.notice-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
}
.title {
  padding-left: 20px;
  margin-bottom: 31px;
  img {
    width: 31px;
    height: 30px;
    margin-top: 2px;
    margin-right: 10px;
    display: inline-block;
    font-size: 0;
  }
  p {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 33px;
    display: inline-block;
    vertical-align: top;
  }
}
.join-list,
.form-pintuan {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 100px;
  margin-bottom: 20px;
  .label {
    width: 80px;
  }
  span {
  }
  .select {
    width: 200px;
  }
}

.join-name {
  width: 30%;
  margin: 20px 50px;
  font-weight: 600;
  font-size: 16px;
  color: #6d6d6d;
  line-height: 28px;
  text-align: left;
}
.join-desc {
  width: 30%;
  margin: 20px 50px;
  font-weight: 600;
  font-size: 16px;
  color: #6d6d6d;
  line-height: 28px;
  text-align: left;
}
.join-is-new {
}
</style>
<style>
.label-text {
  color: #ff724c;
  font-size: 24px;
  margin: 0 3px;
}
</style>
