import TcVod from 'vod-js-sdk-v6'
console.log('%c [ TcVod ]-2', 'font-size:13px; background:pink; color:#bf2c9f;', TcVod)
import {getKey} from '@/api/project'

let tcVod = null
let uploader = null
let percent = 0
const initTvVod = (mediaFile, percentCallBack, doneCallBack, errCallBack, key) => {
  function getSignature() {
    return getKey(key).then(function(response) {
      console.log('%c [ response.data ]-12', 'font-size:13px; background:pink; color:#bf2c9f;', response.data)
      return response.data
    })
  }
  getSignature()
  tcVod = new TcVod({
    getSignature: getSignature // 前文中所述的获取上传签名的函数
  })
  uploader = tcVod.upload({
    mediaFile: mediaFile // 媒体文件（视频或音频或图片），类型为 File
  })
  uploader.on('media_progress', function(info) {
    percent = info.percent
    percentCallBack(percent)
  })
  uploader
    .done()
    .then(function(doneResult) {
      percent = 0
      console.log('%c [ doneResult ]-22', 'font-size:13px; background:pink; color:#bf2c9f;', doneResult)
      doneCallBack(doneResult)
    })
    .catch(function(err) {
      console.log('%c [ err ]-29', 'font-size:13px; background:pink; color:#bf2c9f;', err)
      errCallBack(err)
    })
}

const getPercent = () => {
  return percent
}

export default {
  initTvVod,
  percent,
  getPercent
}
