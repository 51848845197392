<template lang="pug">
.subject
  layoutContainer
    .video
      video(id="player-container-id" width="1200" height="675" preload="auto" playsinline webkit-playsinline)
</template>

<script>
export default {
  name: 'subjectDetail',
  data() {
    return {
      cloudFileUrl: ''
    }
  },
  components: {},
  created() {
    this.cloudFileId = this.$route.query.cloudFileId
    this.appId = this.$route.query.appId
    this.psign = this.$route.query.psign
    // cloudFileId=${item.cloudFileId}&appId=${item.appId}&psign=${item.psign}
    // this.player.src(this.detail.videoUrl) // url 播放地址
    this.$nextTick(() => {
      this.player = window.TCPlayer('player-container-id', {
        fileID: this.cloudFileId,
        appID: this.appId,
        psign: this.psign,
        autoplay: false,
        plugins: {
          ProgressMarker: true,
          ContextMenu: {
            statistic: true,
            levelSwitch: {
              open: true,
              switchingText: 'Start switching',
              switchedText: 'Switch success',
              switchErrorText: 'Switch fail'
            }
          }
        }
      }) // player-container-id 为播放器容器 ID，必须与 html 中一致
    })
  },
  beforeRouteLeave(to, from, next) {
    this.player.dispose()
    next()
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;
.tag {
  min-width: 80px;
  height: 29px;
  border-radius: 28px 28px 28px 28px;
  border: 2px solid #5ad3cd;
  line-height: 25px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 18px;
  color: #5ad3cd;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  padding: 0 22px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}
.del {
  min-width: 80px;
  height: 29px;
  border-radius: 28px 28px 28px 28px;
  border: 2px solid #ff5f56;
  line-height: 25px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 18px;
  color: #ff5f56;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  padding: 0 22px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}
.check {
  min-width: 86px;
  height: 29px;
  background: #5ad3cd;
  border: 2px solid #5ad3cd;
  line-height: 25px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  padding: 0 22px;
  align-items: center;
  justify-content: center;
  border-radius: 28px 28px 28px 28px;
  margin-right: 10px;
  cursor: pointer;
}
.swiper-container {
  width: 310px;
  height: 35px;
  margin-right: 22px;
  overflow: hidden;
  p {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 18px;
    color: #acacac;
    line-height: 21px;
    text-align: left;
    margin-right: 24px;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .swiper-action {
    width: 88px;
    height: 29px;
    background: #5ad3cd;
    border-radius: 28px 28px 28px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    span {
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      line-height: 21px;
      margin-right: 7px;
    }
    .swiper-action-icon {
      width: 16px;
    }
  }
}
.video {
  width: 1200px;
  overflow: hidden;
  height: 675px;
  background: #252525;
  position: relative;
  video {
    width: 100%;
  }
  .notice {
    position: absolute;
    left: 20px;
    bottom: 20px;
    color: #888888;
  }
}
.point {
  width: 12px;
  height: 12px;
  background: #5ad3cd;
  border-radius: 50%;
  margin-right: 10px;
}
.detail {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  .content {
    width: 100%;
  }
  .comp-info-basic-header {
    margin: 0 0 30px 0;
    box-sizing: content-box;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-content: center;
    line-height: 53px;
    border-bottom: 1px solid #eeeeee;
    .detail-name {
      height: 37px;
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 37px;
      margin-right: 30px;
    }
    .desc {
      text-align: center;
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      margin-right: 6px;
      word-break: keep-all;
    }
    .underline {
      text-decoration: underline;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5ad3cd;
      line-height: 28px;
      word-break: keep-all;
      cursor: pointer;
    }
    .purchase-btn {
      .container {
        display: flex;
        align-items: center;
        .price {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          font-size: 40px;
          color: #ff724c;
          margin-left: 30px;
        }
        .discount-price {
          display: inline-block;
          margin-right: 16px;
          display: flex;
          align-items: center;
        }
        .discount-price-decoration {
          text-decoration: line-through;
          font-weight: 400;
          font-size: 22px;
          color: #acacac;
        }
        .btn {
          display: inline-block;
          width: 130px;
          height: 53px;
          background: #ff724c;
          border-radius: 27px;
          text-align: center;
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .comp-share-tab {
    box-sizing: content-box;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-content: center;
    line-height: 53px;
    .action-btns {
      display: flex;
      align-items: center;
      flex-direction: row;
      .share-btn,
      .follow-btn {
        cursor: pointer;
        display: flex;
        margin-right: 45px;
        align-items: center;
        flex-direction: row;
        img {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
        span {
          font-size: 16px;
        }
      }
    }
  }
  .comp-pay-tab {
    box-sizing: content-box;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    height: 45px;
    box-sizing: border-box;
    background: #f6f6f8;
    border-radius: 12px 12px 12px 12px;
    padding: 8px 16px;
    align-items: center;
    .btn-pay-tab {
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #ff724c;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 16px;
      cursor: pointer;
      .icon {
        width: 24px;
        height: 24px;
        margin: 0 10px 0 12px;
      }
      span {
        font-weight: 600;
        font-size: 16px;
        color: #ff724c;
        display: inline-block;
        margin-right: 12px;
        height: 16px;
        line-height: 16px;
      }
    }
    .total {
      font-weight: 500;
      font-size: 18px;
      color: #5ad3cd;
      line-height: 18px;
      text-decoration-line: underline;
    }
  }
  .comp-info-basic-dec {
    margin: 0 0 10px 0;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #333333;
    font-size: 26px;

    .comp-info-basic {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
    }
    .sub-title {
      font-weight: bold;
    }
    .point {
      width: 12px;
      height: 12px;
      background: #5ad3cd;
      border-radius: 50%;
      margin-right: 10px;
    }
    .curse-dec {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-bottom: 30px;
    }
    .curse-content {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
  .comp-info-basic-header {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    .comp-info-basic {
      color: #999999;
      margin-right: 10px;
      white-space: nowrap;
    }
    .comp-info-basic-info {
      color: #333333;
      max-width: 700px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .detail {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5ad3cd;
        line-height: 25px;
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .comp-info-basic-detail {
      margin-left: 10px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5ad3cd;
    }
  }
}
.action {
  padding: 18px 30px 18px 30px;
  position: relative;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  .action-li {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    .tab-actions {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      align-items: center;
    }
    .option {
      line-height: 32px;
      width: 100px;
    }
    .btns {
      display: flex;
      justify-content: flex-start;
      // margin-bottom: 10px;
    }
  }
}
.author {
  padding: 18px 30px 18px 0;
  position: relative;
  background-color: #fff;
  display: flex;
  .title {
    width: 90px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #5ad3cd;
    border-radius: 0px 100px 100px 0px;
    display: inline-block;
    margin-top: 2px;
    margin-right: 30px;
    color: #fff;
  }
  .info {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    .avatar {
      width: 54px;
      height: 54px;
      border-radius: 70px;
      vertical-align: top;
      margin-right: 8px;
      cursor: pointer;
    }
    .name {
      display: inline-block;
      vertical-align: top;
      margin-right: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4bbbb6;
      line-height: 54px;
      cursor: pointer;
    }
  }
  .employee-introduction_visibility {
    display: block;
    max-width: 897px;
  }
}
.project-comment {
  background-color: #fff;
  border-radius: 8px;
  padding: 40px 30px 54px 30px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;

  .project-comment-title {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
    display: flex;
    align-items: center;
  }
  .el-divider {
    height: 1px;
    margin: 30px 0 40px 0;
  }
  .project-comment-content {
    .name {
      vertical-align: top;
      display: inline-block;
      width: 200px;
    }
    .project-comment-content-item-basic {
      position: relative;
      margin-bottom: 28px;
      .project-comment-content-item-basic-name {
        vertical-align: top;
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 20px;
      }
      .project-comment-content-item-basic-objectName {
        vertical-align: top;
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-left: -16px;
      }
      .project-comment-content-item-basic-score {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 2px 7px 3px 0;
        img {
          width: 20px;
          height: 20px;
          display: inline-block;
        }
      }
      .project-comment-content-item-basic-time {
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        vertical-align: top;
        margin-left: 30px;
      }
    }
    .project-comment-content-item-basic-content {
      margin-bottom: 50px;
      span {
        display: inline-block;
        width: calc(100% - 64px);
        vertical-align: middle;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 2px;
        margin-left: 42px;
        cursor: pointer;
      }
    }
    .comment-btn {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      padding: 4px 79px;
      background: #ffaa30;
      border-radius: 14px;
      width: 200px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
.qr-code {
  width: 138px;
  height: 138px;
  margin: 18px auto 20px;
  display: block;
}
.share-text {
  margin: 0 auto;
  text-align: center;
}
</style>
<style>
.tcplayer {
  margin: 0 auto;
}

.tip-node {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5em;
  left: 1em;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 5px 10px;
}

.tip-icon-close {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-left: 10px;
  background-image: url(https://tcplayer-1306264703.cos.ap-nanjing.myqcloud.com/picture/icon-close.png);
  background-size: cover;
  cursor: pointer;
}

.page-node {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content {
  text-align: center;
}

.page-content a {
  display: flex;
  width: 120px;
  height: 28px;
  border-radius: 14px;
  color: #663d00;
  background-image: linear-gradient(90deg, #ffdf89 0, #f2ca5b 100%);
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0 auto;
}

.page-content #replay-btn::before {
  content: '';
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  background: url(https://tcplayer-1306264703.cos.ap-nanjing.myqcloud.com/picture/icon-replay.png);
  background-size: contain;
}

.page-content #replay-btn {
  margin: 10px auto 0;
}
</style>
