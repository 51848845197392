<template lang="pug">
.content
  .tab-bar
    .tab(
      v-for="(item, index) in tabBarList"
      :key="index"
      @click="selectType = item.value;"
    )
      span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
      .select-status(v-if="selectType === item.value")
  .content-container(v-if="selectType === 1")
    el-empty.el-empty(description="暂无数据" :image-size="200" v-if="records.length === 0")
    .search-tab(v-if="records.length > 0")
      .search-account
        el-select(v-model="status" placeholder="请选择状态" style="margin-right: 20px")
          el-option(label="全部" :value="-1")
          el-option(label="待审核" :value="1")
          el-option(label="生效中" :value="2")
          el-option(label="已解除" :value="3")
          el-option(label="竞选中" :value="4")
          el-option(label="已落选" :value="5")
          el-option(label="已拒绝" :value="6")
          el-option(label="已失效" :value="7")
        el-select(v-model="establishType" placeholder="请选择建立方式" style="margin-right: 20px")
          el-option(label="代销" value="代销")
          el-option(label="分享" value="分享")
          el-option(label="导流" value="导流")
          el-option(label="推广(推广链接)" value="推广(推广链接)")
          el-option(label="推广(手动关联)" value="推广(手动关联)")
      .search-code
        el-select(v-model="recordType" placeholder="请选择记录范围" style="margin-right: 20px;width: 250px;")
          el-option(label="我的记录" :value="1")
          el-option(label="全站记录" :value="2")
        el-select(v-model="queryType" placeholder="请选择关联" v-if="hasManageAuth" style="margin-right: 20px;width: 250px;")
          el-option(label="关联单号" :value="1")
          el-option(label="关联人ID" :value="2")
          el-option(label="被关联人ID" :value="3")
          el-option(label="被关联人平台号" :value="4")
          el-option(label="昵称" :value="5")
          el-option(label="姓名" :value="6")
          el-option(label="公司全称" :value="7")
        el-input(v-model="queryContent" placeholder="请输入内容")
    .list(v-for="item in records" v-if="records.length > 0")
      .options
        .label 关联单号:
        .params {{ item.relationId }}
      .options
        .label 关联人ID:
        .params {{item.relateUserId}}
      .options
        .label 被关联人ID:
        .params {{ item.relatedUserId }}
      .options
        .label 被关联人平台:
        .params {{item.relatedBelongPlatform}}
      .options
        .label 被关联人平台号:
        .params {{ item.relatedPlatformUserId }}
      .options
        .label 沟通记录:
        .params 
      .options
        .label 对方属于:
        .params {{ item.identityType }}
      .options
        .label 昵称:
        .params {{ item.nickName }}
      .options
        .label 姓名:
        .params {{ item.name }}
      .options
        .label 公司全称:
        .params {{ item.companyName }}
      .options
        .label 状态:
        .params
          el-tag(type="success" v-if="item.status === 1" style="margin-right:5px;") 待审核
          el-tag(type="success" v-if="item.status === 2" style="margin-right:5px;") 生效中
          el-tag(type="success" v-if="item.status === 3" style="margin-right:5px;") 已解除
          el-tag(type="success" v-if="item.status === 4" style="margin-right:5px;") 竞选中
          el-tag(type="success" v-if="item.status === 5" style="margin-right:5px;") 已落选
          el-tag(type="success" v-if="item.status === 6" style="margin-right:5px;") 已拒绝
          el-tag(type="success" v-if="item.status === 7" style="margin-right:5px;") 已失效
      .options
        .label 有效期至:
        .params {{ item.refuseReason }}
      .options
        .label 拒绝原因:
        .params 
          el-input(v-model="item.refuseReason" placeholder="请输入内容" style="width: 500px" :disabled="!hasMarketingAuth")
      .options
        .label 建立方式:
        .params {{item.establishType}}
      .options
        .label 创建时间:
        .params {{ item.createUserName }}
      .options
        .label 修改人:
        .params {{item.modifyUserName}}
      .options
        .label 创建时间:
        .params {{ item.createTime }}
      .options
        .label 竞争人数:
        .params(style="color: #ff724c;margin-right: 20px;") {{ item.electionsNum }} 
          span(style="color: #409EFF;margin-right: 20px;text-decoration: underline;cursor: pointer;" @click="checkElection(item)") 查看全部
      .options
        .label 审核人ID:
        .params {{ item.auditUserId }}
      .btns
        .action(v-if="item.showPass" @click="relationPassed(item)") 通过
        .action(v-if="item.showRejected" @click="relationReject(item)") 拒绝
        .action(v-if="item.showDelete" @click="relationRemove(item)") 删除
        .action(v-if="item.showReleased" @click="relationReleased(item)") 解除    
    el-pagination(
      v-if="records.length !== 0"
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentChange"
      :total="total"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 2")
    .notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        div(v-for="item in isTips")
          p {{item.question}}
          span {{item.answer}}
  dialogMask(:show.sync="dialog", :showClose="true")
    .title
      div
        img(src="./../../assets/projectSet.png")
        p 竞争排名
      .deadline 截止时间：{{competeInfo.deadline}}
    .content-dialog
      el-table(:data="competeInfo.records" style="width: 100%;margin-bottom:30px")
        el-table-column(prop="competeUserId" label="竞争者")
        el-table-column(prop="establishType" label="建立方式")
        el-table-column(prop="score" label="分数")
        el-table-column(prop="status" label="状态")
          template(slot-scope="scope")
            el-tag(type="success") {{scope.row.status}}
      .common
        .options
          .label 我的数据
          .name  (近2年)
        .options
          .label 总佣金：{{competeInfo.totalCommission}}
          .name 人均佣金：{{competeInfo.perCapitaCommission}}
        .options
          .label 业绩增长率：{{competeInfo.performanceGrowthRate}}
          .name 客户流失率：{{competeInfo.customerLossRate}}
      .notice(v-if="tips")
        img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
        div(style="flex: 1")
          span(v-for="item in tips") · {{item}}
</template>
<script>
import {
  qaList,
  relationList,
  relationReleased,
  relationRemove,
  relationReject,
  relationPassed,
  competeList,
  getTips
} from '../../api/orderAccount'
import {currentLoginUser} from '@/api/project'
import dialogMask from '@/components/common/dialog.vue'

export default {
  data() {
    return {
      tabBarList: [
        {
          name: '关联列表',
          value: 1
        },
        {
          name: '什么是“关联"？',
          value: 2
        }
      ],
      selectType: 1,
      isTips: [],
      status: '',
      tips: [],
      recordType: '',
      establishType: '',
      dialog: false,
      queryType: '',
      queryContent: '',
      hasManageAuth: false,
      records: [{}],
      pageSize: 10,
      page: 1,
      total: 0,
      hasMarketingAuth: false,
      competeInfo: {}
    }
  },
  components: {
    dialogMask
  },
  props: {},
  watch: {
    status() {
      this.currentChange(1)
    },
    establishType() {
      this.currentChange(1)
    },
    recordType() {
      this.currentChange(1)
    },
    queryType() {
      this.currentChange(1)
    },
    queryContent() {
      this.currentChange(1)
    }
  },
  mounted() {
    qaList({position: '关联-什么是关联'}).then(res => {
      this.isTips = res.data
    })
    getTips({position: '关联-竞争排名'}).then(res => {
      this.tips = res.data
    })
    this.search()
    currentLoginUser().then(res => {
      this.userId = res.data.userId
      this.hasManageAuth = res.data.hasManageAuth
      this.hasMarketingAuth = res.data.hasMarketingAuth
    })
  },
  methods: {
    checkElection(item) {
      competeList({relationId: item.relationId}).then(res => {
        this.competeInfo = res.data
        this.dialog = true
      })
    },
    search() {
      relationList({
        status: this.status,
        recordType: this.recordType,
        establishType: this.establishType,
        queryType: this.queryType,
        queryContent: this.queryContent,
        pageNum: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.total = res.data.total
        this.records = res.data.records
      })
    },
    relationReleased(info) {
      relationReleased({recordId: info.recordId}).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.currentChange(1)
          return
        }
        this.$message.error(res.data)
      })
    },
    relationRemove(info) {
      relationRemove({recordId: info.recordId}).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.currentChange(1)
          return
        }
        this.$message.error(res.data)
      })
    },
    relationReject(info) {
      if (info.refuseReason === '') {
        this.$message.error('拒绝原因不能为空')
        return
      }
      relationReject({recordId: info.recordId, refuseReason: info.refuseReason}).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.currentChange(1)
          return
        }
        this.$message.error(res.data)
      })
    },
    relationPassed(info) {
      relationPassed({recordId: info.recordId}).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.currentChange(1)
          return
        }
        this.$message.error(res.data)
      })
    },
    currentChange(val) {
      this.page = val
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100;
  padding: 20px 40px;
  flex-direction: column;
  position: relative;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.content-container {
  box-sizing: border-box;
  padding-top: 20px;
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-right: 56px;
  cursor: pointer;
  text-align: center;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.notice {
  padding: 24px 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 80px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  span {
    display: block;
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 10px;
    color: #5ad3cd;
    font-weight: bold;
  }
}
.notice-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
  margin-right: 30px;
}
.search-account,
.search-code {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.search-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.list {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  .options {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    text-align: center;
    margin-bottom: 15px;
    align-items: center;
  }
  .label {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 6px;
    vertical-align: bottom;
    align-items: flex-end;
    margin-right: 20px;
    width: 130px;
    span {
      font-weight: 500;
      font-size: 20px;
    }
    .icon {
      font-size: 14px;
      margin-right: 2px;
    }
    .num {
      font-size: 16px;
    }
  }
  .name {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
  .tabs {
    margin-bottom: 10px;
    position: relative;
  }
}
.btns {
  display: flex;
  justify-content: flex-start;
}
.action {
  height: 40px;
  border-radius: 20px;
  background: #5ad3cd;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  padding: 0 20px;
}
.title {
  padding-left: 20px;
  margin-bottom: 31px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 31px;
    height: 30px;
    margin-top: 2px;
    margin-right: 10px;
    display: inline-block;
    font-size: 0;
  }
  p {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 33px;
    display: inline-block;
    vertical-align: top;
  }
}
.content-dialog {
  padding: 0 40px 0 40px;
}
.deadline {
  margin-right: 60px;
  color: #000;
}
.common {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
  .options {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
  }
  .label {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 6px;
    vertical-align: bottom;
    align-items: flex-end;
    span {
      font-weight: 500;
      font-size: 20px;
    }
  }
}
</style>
