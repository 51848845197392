<template lang="pug">
.content
  layoutContainer
    .content(:style="{'min-height': `${containerHeight}px`, background: '#ffffff', padding: '10px 20px 20px', 'border-radius': '12px'}")
      .search-course-name
        .label 产品名称:
        .name {{ courseName }}
        img(src="/img/关闭@2x.png" @click="clearCourse")
      .search-tab
        .search-name
          img(src="/img/saleRecord-icon.png")
          span 销售记录
          .get-price
            img(src="/img/price-icon.png")
            span 去提现
        .search-nav
          el-form(ref="form" label-width="80px")
            el-form-item(label="搜索")
              el-input(placeholder="请输入内容" v-model="queryContent" class="input-with-select")
                el-select(v-model="queryType" slot="prepend" placeholder="请选择")
                  el-option(label="全部" value="-1")
                  el-option(label="产品名称" value="1")
                  el-option(label="订单号" value="2")
                  el-option(label="发布人" value="4" v-if="isOperator")
                el-button(slot="append" icon="el-icon-search" @click="clearCourse")
              
      el-table.table(:data="saleRecordList" stripe style="width: 100%")
        el-table-column(prop="productName" label="产品名称")
        el-table-column(prop="orderId" label="订单号")
        el-table-column(prop="publisherName" label="发布人" )
        el-table-column(prop="buyerName" label="购买人")
        el-table-column(prop="price" label="价格" )
        el-table-column(prop="discounts" label="优惠" width="170")
          template(slot-scope="scope")
            div(v-for="item in scope.row.discounts" style="display: inline-block;margin-right: 10px;")
              el-tag(type="success" v-if="item === 1") 用券
              el-tag(type="success" v-if="item === 2") 砍价
              el-tag(type="success" v-if="item === 3") 拼团
              el-tag(type="success" v-if="item === 4") 活动
              el-tag(type="success" v-if="item === 5") 推广
        el-table-column(prop="anticipateCommission" label="预计佣金" )
        el-table-column(prop="payStatus" label="状态" )
          template(slot-scope="scope")
            el-tag(type="success" v-if="scope.row.payStatus === 1") 已支付
            el-tag(type="danger" v-if="scope.row.payStatus === 2") 已退款
        el-table-column(prop="orderCreateTime" label="下单时间"  width="170")
      el-pagination.pagination(background layout="prev, pager, next" :total="total" @current-change="currentChange")
</template>

<script>
import {saleRecord} from '@/api/project'
import {checkAuth} from '@/api/product'

export default {
  name: 'Project',
  components: {},
  data() {
    return {
      containerHeight: 0,
      courseName: '',
      courseId: '',
      queryType: '-1',
      queryContent: '',
      pageNum: 1,
      pageSize: 10,
      total: 0,
      isOperator: false,
      saleRecordList: []
    }
  },
  watch: {},
  mounted() {
    checkAuth({and: true, authList: [1]}).then(res => {
      this.isOperator = res.code === 200
    })
    const {courseId, courseName} = this.$route.query
    this.courseId = courseId
    this.courseName = courseName
    this.$nextTick(() => {
      const screenHeight = document.documentElement.clientHeight
      const headerHeight = document.getElementsByClassName('headerBox')[0].offsetHeight
      const footerHeight = document.getElementsByClassName('footer-container')[0].offsetHeight
      this.containerHeight = screenHeight - headerHeight - footerHeight - 70
    })
    this.search()
  },
  methods: {
    clearCourse() {
      this.courseId = ''
      this.courseName = ''
      this.pageNum = 1
      this.search()
    },
    currentChange(page) {
      this.pageNum = page
      this.search()
    },
    search() {
      const params = {
        businessType: '知识视频',
        queryType: this.queryType,
        queryContent: this.queryContent,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.courseId) params.productId = this.courseId
      saleRecord(params).then(res => {
        this.saleRecordList = res.data.records
        this.total = res.data.total
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;

.search-course-name {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .label {
    margin-right: 10px;
  }
  img {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    cursor: pointer;
  }
  .name {
    font-size: 20px;
    font-weight: 600;
  }
}
.search-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
  span {
    font-weight: 500;
    font-size: 24px;
    color: #333333;
  }
  .get-price {
    background: rgba(255, 114, 76, 0.08);
    border-radius: 34px 34px 34px 34px;
    border: 1px solid #ff724c;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
    span {
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      font-size: 20px;
      color: #ff724c;
    }
  }
}
.pagination {
  text-align: center;
  margin: 30px 0;
}
.table {
  margin-top: 20px;
}
</style>
<style lang="scss">
.content {
  .search-nav {
    width: 600px;
    .el-input-group__prepend {
      width: 120px !important;
    }
  }
  .el-table .warning-row {
    background: #f6f6f8;
  }

  .el-table .success-row {
    background: #f6f6f8;
  }
}
</style>
