<template lang="pug">
.publish-content
  dialogMask(:show.sync="dialogVisible", :showClose="true")
    .title
      img(src="./../../assets/aircraft.png")
      p 审核记录
    el-table(
      header-align="center"
      :data="tableData"
      style="margin:20px;width: auto;")
      el-table-column(prop="courseName" label="名称" width="140")
      el-table-column(prop="action" label="动作" width="100")
      el-table-column(prop="status" label="状态" width="100")
        template(slot-scope="scope")
          el-tag(type="success" v-if="scope.row.status === 1") 待审核
          el-tag(type="success" v-if="scope.row.status === 2") 已通过
          el-tag(type="success" v-if="scope.row.status === 3") 已拒绝
      el-table-column(prop="refuseReason" label="原因")
      el-table-column(prop="createTime" label="时间" width="160")
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {applyRecordList} from '@/api/project'

export default {
  name: 'publishDialog',
  props: ['data', 'showPublish', 'courseId'],
  data() {
    return {
      dialogVisible: false,
      tableData: []
    }
  },
  watch: {
    showPublish(val) {
      if (val) this.dialogVisible = val
    },
    dialogVisible(val) {
      if (val) {
        applyRecordList({
          courseId: this.courseId
        }).then(res => {
          this.tableData = res.data
        })
      }
      if (!val) {
        this.$emit('update:showPublish', false)
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.publish-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 31px;
    img {
      width: 31px;
      height: 30px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
}
</style>
