<template lang="pug">
.content
  .tab-bar
    .tab(
      v-for="(item, index) in tabBarList"
      :key="index"
      @click="selectType = item.value;"
    )
      span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
      .select-status(v-if="selectType === item.value")
  .content-container(v-if="selectType === 1")
    .guidance-page
      .icon
      .guidance-page-content
        p 我的导流链接
        p 用户在任何地方，点击您的下述链接，来到全倾平台注册并下单后，您即可获得佣金。
          span  https://www.quanqingkeji.com/#/home&promoter_id={{userId}}&promote_type=guide
        .btns
          .action(@click="copy")
            span 复制
    .guidance-page
      .icon
      .guidance-page-content
        p 导流场景
        div(style="flex: 1; margin-bottom: 40px")
          div.scene-spot-container(v-for="item in scenesTexts")
            span.scene-spot 
            span {{item}}
    .guidance-page
      .icon
      .guidance-page-content
        p 导流图片
        p 如需导流图片，可保存下方图片至您的服务器上（尽量暂别直接引用下图网址，因为图片名会变导致失效），或也可自行做个图片，都是一样的效果。
        img.guidance-img(v-for="item in scenesImgs" :src="item.picUrl" :width="item.width" :height="item.height") 
        .notice(v-if="tips")
          img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
          div(style="flex: 1")
            span(v-for="item in tips") · {{item}}
  .content-container(v-if="selectType === 2")
    .guidance-page
      .icon
      .guidance-page-content
        p 我的导流链接
        p 除了前述的普通导流链接，全倾还会拿出个别商品（暂只支持知识视频类），不论原价如何，一律以1元的推广价售卖，您也可以用以下的1元品的链接来导流。
        div.guidance-url-content(v-for="(item, index) in guideProducts" :key="index" v-if="index < 4 || (index >= 4 && guidanceUrlMore)")
          div.scene-spot-container
            span.scene-spot 
            span {{item.productName}}
          span.scene-spot-url {{getUrl(item, index)}}
        .btns.guidance-btn
          .action.guidance-action(@click="handleShowMoreUrl" v-show="guideProducts.length > 3 && !guidanceUrlMore")
            span 查看全部
    .guidance-page
      .icon
      .guidance-page-content
        p 导流图片
        p 如需导流图片，可保存下方图片至您的服务器上（尽量暂别直接引用下图网址，因为图片名会变导致失效），或也可自行做个图片，都是一样的效果。
        img.guidance-img(v-for="item in scenesImgsOneYuan" :src="item.picUrl" :width="item.width" :height="item.height") 
        .notice(v-if="tipsOneYuan.length")
          img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
          div(style="flex: 1")
            span(v-for="item in tipsOneYuan") · {{item}}
  .content-container(v-if="selectType === 3")
    .common
      .options
        .name 有{{phoneOrderInfo.registerTotal}}人通过我的分享注册了
    .search-tab
      .search-account
        el-input(v-model="phoneNumber" placeholder="请输入注册者手机号")
    el-table(:data="phoneOrderInfo.items" style="width: 100%")
      el-table-column(prop="registerUserPhoneNumber" label="注册者手机号")
      el-table-column(prop="registerTime" label="注册时间")
    el-pagination(
      class="el-pagination"
      background
      :page-size="pagePhoneSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentPhoneChange"
      :total="phoneTotal"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 4")
    .common
      .options
        .label {{orderInfo.total}}
        .name 导流来的用户下单数
      .options
        .label
          span.icon  ¥
          span {{orderInfo.orderTotalAmount}}
        .name 下单总金额

      .options
        .label
          span.icon  ¥
          span {{orderInfo.anticipativeTotalCommission}}
        .name 佣金总额
      .options
        .label
          span.icon  ¥
          span {{orderInfo.anticipativeEffectedTotalCommission}}
        .name 已生效佣金
    .search-tab
      .search-account
        el-select(v-model="status" placeholder="请选择状态" @change="(value) => handleSelectChange(value, 'status')")
          el-option(label="全部" :value="-1")
          el-option(label="未生效" :value="0")
          el-option(label="已生效" :value="1")
      .search-code
        el-select(v-model="queryType" placeholder="请选择搜索类型" @change="(value) => handleSelectChange(value, 'queryType')" style="margin-right: 20px;width: 250px;")
          el-option(label="下单人 ID" value="1")
          el-option(label="订单号" value="2")
        el-input(v-model="queryContent" placeholder="请输入内容")
    el-table(:data="orderInfo.items" style="width: 100%")
      el-table-column(prop="orderUserId" label="下单人ID")
      el-table-column(prop="recordId" label="订单号")
      el-table-column(prop="orderAmount" label="订单金额")
        template(slot-scope="scope")
          span ¥ {{scope.row.orderAmount}}
      el-table-column(prop="anticipativeCommission" label="预期佣金")
        template(slot-scope="scope")
          span ¥ {{scope.row.orderAmount}}
          el-tag(type="success" style="margin-right:5px;") {{item.commissionIsEffected ? '已生效' : '未生效'}}
      el-table-column(prop="orderTime" label="下单时间")
    el-pagination(
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentChange"
      :total="total"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 5")
    .notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        div(v-for="item in isTips")
          p {{item.question}}
          span {{item.answer}}
</template>
<script>
import {
  getTips,
  getGuidanceScenes,
  getGuidancePictures,
  registerList,
  orderRecords,
  qaList,
  getGuideProducts
} from '../../api/orderAccount'
import {currentLoginUser} from '@/api/project'

export default {
  data() {
    return {
      userId: '',
      tabBarList: [
        {
          name: '导流',
          value: 1
        },
        {
          name: '导流（1元品）',
          value: 2
        },
        {
          name: '注册记录',
          value: 3
        },
        {
          name: '下单记录',
          value: 4
        },
        {
          name: '什么是“导流”？',
          value: 5
        }
      ],
      selectType: 1,
      tips: [],
      scenesTexts: [],
      scenesImgs: [],
      tipsOneYuan: [],
      scenesImgsOneYuan: [],
      guideProducts: [],
      guidanceUrlMore: false, // 查看全部按钮
      phoneOrderInfo: {},
      phoneNumber: '',
      pageSize: 10,
      pagePhoneSize: 10,
      page: 1,
      pageNum: 1,
      phoneTotal: 0,
      total: 0,
      promoteType: '导流',
      orderInfo: {},
      status: -1,
      queryType: '',
      queryContent: '',
      isTips: []
    }
  },
  props: {},
  mounted() {
    // tips（导流）
    getTips({position: '导流-普通导流'}).then(res => {
      this.tips = res.data
    })
    // tips（一元品）
    getTips({position: '导流-导流(1元品)'}).then(res => {
      this.tipsOneYuan = res.data
    })
    // 导流场景文案
    getGuidanceScenes({position: '导流-导流场景'}).then(res => {
      this.scenesTexts = res.data
    })
    // 导流图(导流)
    getGuidancePictures({guideType: '普通导流'}).then(res => {
      this.scenesImgs = res.data
    })
    // 导流图(一元品)
    getGuidancePictures({guideType: '1元品导流'}).then(res => {
      this.scenesImgsOneYuan = res.data
    })
    // 用户id
    currentLoginUser().then(res => {
      this.userId = res.data.userId
    })
    // 什么是"导流"
    qaList({position: '导流-什么是导流'}).then(res => {
      this.isTips = res.data
    })
    // 查询1元品导流链接
    getGuideProducts().then(res => {
      this.guideProducts = res.data
    })
  },
  watch: {
    phoneNumber() {
      this.pageNum = 1
      this.searchPhone()
    },
    queryContent() {
      this.page = 1
      this.search()
    }
  },
  methods: {
    getUrl(info, index) {
      if(index === 0) {
        return `${window.location.origin}/#/techVideo?show_promotion_product=true&promoter_id=${info.promoterId}&promote_type=guide`
      }
      return `${window.location.origin}/#/techVideoDetail?id=${info.productId}&promoter_id=${info.promoterId}&promote_type=guide`
    },
    copyTextToClipboard(text) {
      const input = document.createElement('input')
      input.value = text
      document.body.appendChild(input)
      input.select() // 选择输入框的内容
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$message.success('复制成功')
    },
    copy() {
      this.copyTextToClipboard(`https://www.quanqingkeji.com/#/home&promoter_id=${this.userId}&promote_type=share`)
    },
    // 查看全部
    handleShowMoreUrl() {
      this.guidanceUrlMore = !this.guidanceUrlMore
    },
    // 注册记录
    searchPhone() {
      registerList({
        promoteType: this.promoteType,
        phoneNumber: this.phoneNumber,
        pageNum: this.pageNum,
        pageSize: this.pagePhoneSize
      }).then(res => {
        this.phoneTotal = res.data.total
        this.phoneOrderInfo = res.data
      })
    },
    // 订单记录
    search() {
      orderRecords({
        promoteType: this.promoteType,
        status: this.status,
        queryType: this.queryType,
        queryContent: this.queryContent,
        pageNum: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.orderInfo = res.data
      })
    },
    // 注册记录分页
    currentPhoneChange(val) {
      this.pageNum = val
      this.searchPhone()
    },
    // 订单分页
    currentChange(val) {
      this.page = val
      this.search()
    },
    // 下拉选change事件
    handleSelectChange(value, type) {
      if (type === 'status') {
        this.status = value
      }
      if (type === 'queryType') {
        this.queryType = value
      }
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.search-account,
.search-code {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.search-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content {
  width: 100%;
  height: 100;
  padding: 20px 40px;
  flex-direction: column;
  position: relative;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-right: 56px;
  cursor: pointer;
  text-align: center;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.content-container {
  box-sizing: border-box;
  padding-top: 20px;
}
.guidance-page {
  margin-bottom: 20px;
  border-bottom: 1px dashed #dcdfe6;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .icon {
    width: 8px;
    height: 8px;
    margin: 10px 30px 0 30px;
    border-radius: 50%;
    background-color: #5ad3cd;
  }
}
.guidance-page-content {
  flex: 1;
  p {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    color: rgb(51, 51, 51);
    line-height: 26px;
    margin-bottom: 30px;
  }
}
.notice {
  padding: 24px 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 80px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  span {
    display: block;
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 10px;
    color: #5ad3cd;
    font-weight: bold;
  }
}
.notice-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
  margin-right: 30px;
}
.scene-spot-container {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
.scene-spot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 30px;
  background-color: #5ad3cd;
}
.guidance-url-content {
  margin-bottom: 16px;
}
.scene-spot-url {
  margin-left: 65px;
  word-break: break-all;
  display: inline-block;
}
.btns {
  display: flex;
  justify-content: flex-start;
}
.action {
  height: 40px;
  border-radius: 20px;
  background: #5ad3cd;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 40px;
  padding: 0 20px;
}
.guidance-btn {
  margin-bottom: 40px;
}
.guidance-action {
  margin: 0 0 0 60px;
}
.guidance-img {
  display: block;
  margin-bottom: 20px;
}
.common {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
  .options {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
  }
  .label {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 6px;
    vertical-align: bottom;
    align-items: flex-end;
    span {
      font-weight: 500;
      font-size: 20px;
    }
    .icon {
      font-size: 14px;
      margin-right: 2px;
    }
    .num {
      font-size: 16px;
    }
  }
  .name {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
  .tabs {
    margin-bottom: 10px;
    position: relative;
  }
}
</style>
